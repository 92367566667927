import React, { useState, useEffect, useCallback } from 'react'
import * as actions from '../../store/actions'
import { connect } from 'react-redux'
import Controller from './Controller'
import TopMenu from '../UI/TopMenu'
import Spinner from '../UI/Spinner'
import NotFound from '../UI/NotFound'

const DetailedSubject = ({
    currentSubject,
    getSubject,
    subjects,
    getAllSubjects,
    resources,
    getAllResources,
    permissions,
    getSubjectPermissions,
    members,
    getMembers,
    actions,
    getActions,
    match,
    history,
    errors,
}) => {
    const id = match.params.id
    const [previousId, setPreviousId] = useState(0)
    const [previousPermissions, setPreviousPermissions] = useState([])

    const retrieveData = useCallback(() => {
        getAllSubjects()
        getAllResources()
        getActions()
    }, [getAllSubjects, getAllResources, getActions])

    useEffect(retrieveData, [])

    useEffect(() => {
        if (id !== previousId) {
            getSubject(id)
            getSubjectPermissions(id)
            getMembers('g', id)
            setPreviousId(id)
        }
    }, [id, subjects.length, getAllSubjects, actions.length, getActions])

    useEffect(() => {
        if (permissions.data.length !== 0 && actions.length !== 0) {
            setPreviousPermissions(permissions.data)
            // first sorting by type then by actions
            permissions.data.sort((a, b) => {
                const subjectType = (a, b) => a[2].localeCompare(b[2])
                const actionsSort = (a, b) => actions.actions.indexOf(a[3])
                actions.actions.indexOf(b[3])

                return subjectType(a, b) || actionsSort(a, b)
            })
        }
    }, [permissions.data, getSubjectPermissions, actions, getActions])

    const getSubjectType = () => {
        if (currentSubject !== null && currentSubject.id === id) {
            return currentSubject.subjectType
        } else {
            let type = id.split('-', 2)
            return type[0]
        }
    }

    const getSubjectName = () => {
        if (currentSubject !== null && currentSubject.id === id) {
            return currentSubject.name
        } else {
            return id
        }
    }

    if (errors && errors.e && errors.id === id && permissions.data.length === 0)
        return <NotFound history={history} />

    return (currentSubject !== null && previousId === currentSubject.id) ||
        (errors &&
            errors.id === id &&
            previousId === id &&
            permissions.data === previousPermissions) ? (
        <div className='container'>
            <TopMenu
                first='subjects'
                second={getSubjectType()}
                page={getSubjectName()}
                addRoleAssignment
                actions={actions}
                resources={resources}
            />
            <div className='row'>
                <div className='col s12'>
                    <Controller
                        history={history}
                        resources={resources}
                        subjects={subjects}
                        currentId={id}
                        permissions={permissions.data}
                        members={members}
                    />
                </div>
            </div>
        </div>
    ) : (
        <Spinner />
    )
}

const mapStateToProps = ({
    subjects,
    resources,
    currentSubject,
    actions,
    members,
    permissions,
    errors,
}) => {
    return {
        subjects,
        resources,
        currentSubject,
        actions,
        members,
        permissions,
        errors,
    }
}

export default connect(mapStateToProps, actions)(DetailedSubject)
