import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import AuthCallback from '../tools/AuthCallback'
import CheckAuth from '../tools/CheckAuth'
import Home from './main/Home'
import Navbar from './UI/Navbar'
import Resources from './resources/Resources'
import DetailedResource from './resources/DetailedResource'
import Subjects from './subjects/Subjects'
import DetailedSubject from './subjects/DetailedSubject'
import NotFound from './UI/NotFound'
import './App.scss'

const App = () => {
    return (
        <Router>
            <CheckAuth>
                <Navbar />
                <Switch>
                    <Route exact path='/' component={Home} />
                    <Route exact path='/resources' component={Resources} />
                    <Route
                        exact
                        path='/resources/:id'
                        component={DetailedResource}
                    />
                    <Route exact path='/subjects' component={Subjects} />
                    <Route
                        exact
                        path='/subjects/:id'
                        component={DetailedSubject}
                    />
                    <Route
                        exact
                        path='/oidc-callback'
                        component={AuthCallback}
                    />
                    <Route path='*' component={NotFound} />
                </Switch>
            </CheckAuth>
        </Router>
    )
}

export default App
