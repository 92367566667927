import React from 'react'

const Spinner = ({ location, noPadding }) => {
    return (
        <div className={!noPadding ? 'container' : ''}>
            <div className='spinner'>
                <span className='spinner-text'>
                    <span>Loading </span>
                    <span className='loader__dot'>.</span>
                    <span className='loader__dot'>.</span>
                    <span className='loader__dot'>.</span>
                </span>
                <div className='sk-chase'>
                    <div className='sk-chase-dot'></div>
                    <div className='sk-chase-dot'></div>
                    <div className='sk-chase-dot'></div>
                    <div className='sk-chase-dot'></div>
                    <div className='sk-chase-dot'></div>
                    <div className='sk-chase-dot'></div>
                </div>
            </div>
        </div>
    )
}

export default Spinner
