import React, { useState, useEffect, useCallback } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import * as actions from '../../store/actions'
import { connect } from 'react-redux'
import TopMenu from '../UI/TopMenu'
import Spinner from '../UI/Spinner'
import Table from '../UI/Table'
import qs from 'query-string'
import '../main/main.scss'

const Subjects = ({ getAllSubjects, subjects, history, location }) => {
    const page = 'Subjects'
    const [search, setSearch] = useState('')
    const [filtered, setFiltered] = useState(subjects)
    const [searchSubjects, setSearchSubjects] = useState()

    useEffect(() => {
        let temp = filterAndSort(subjects)

        if (temp.length > 8) temp.length = 8
        setFiltered(temp)
    }, [search, setSearch])

    const retrieveData = useCallback(() => {
        getAllSubjects()
    }, [getAllSubjects])

    useEffect(retrieveData, [])

    useEffect(() => {
        if (subjects.length !== 0) {
            const param = qs.parse(location.search).search
            if (param !== undefined) {
                setSearch(param)
                setSearchSubjects(
                    subjects
                        .filter((a) =>
                            a.name === null
                                ? a.name
                                : a.name
                                      .toLowerCase()
                                      .includes(param.toLowerCase())
                        )
                        .sort((a, b) =>
                            a.subjectType.localeCompare(b.subjectType)
                        )
                )
            }
        }
    }, [subjects, getAllSubjects, location.search])

    const handleInputChange = (e) => {
        subjects.length === 0 && getAllSubjects()
        setSearch(e.target.value)
    }

    const handleSearch = (e) => {
        e.preventDefault()

        setSearchSubjects(filterAndSort(subjects))
    }

    const filterAndSort = (data) => {
        return data
            .filter((a) =>
                a.name === null ? a.name : a.name.toLowerCase().includes(search)
            )
            .sort((a, b) => a.subjectType.localeCompare(b.subjectType))
    }

    return (
        <div className='container'>
            <TopMenu first='subjects' second='Browse' page={page} />
            {subjects !== null ? (
                <div className='row'>
                    <div className='col s12'>
                        <form className='search-form'>
                            <div className='row'>
                                <Autocomplete
                                    id='subjectsAutocomplete'
                                    options={filtered}
                                    noOptionsText={'Type to retrieve results..'}
                                    groupBy={(g) => g.subjectType}
                                    getOptionLabel={(o) => o.name}
                                    onInputChange={(e) => handleInputChange(e)}
                                    onChange={(event, value) =>
                                        history.push('/subjects/' + value.id)
                                    }
                                    renderInput={(params) => (
                                        <div
                                            className='input-field col s6'
                                            ref={params.InputProps.ref}
                                        >
                                            <input
                                                id='search'
                                                type='text'
                                                className='validate'
                                                {...params.inputProps}
                                            />
                                            <label htmlFor='search'>
                                                Search
                                            </label>
                                        </div>
                                    )}
                                />
                                <div className='input-field col s6'>
                                    <button
                                        className='btn btn-small'
                                        onClick={(e) => handleSearch(e)}
                                    >
                                        Search
                                    </button>
                                </div>
                            </div>
                        </form>
                        <Table
                            columns={[
                                { name: 'Name', align: 'left' },
                                { name: 'Id', align: 'left' },
                                { name: 'Type', align: 'right' },
                            ]}
                            rows={searchSubjects}
                            history={history}
                            type='subjects'
                            fixedTable
                            headers={['apikey', 'client', 'user']}
                        />
                    </div>
                </div>
            ) : (
                <Spinner />
            )}
        </div>
    )
}

const mapStateToProps = ({ subjects, errors }) => {
    return { subjects, errors }
}

export default connect(mapStateToProps, actions)(Subjects)
