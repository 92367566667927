import React, { useState, useEffect } from 'react'
import * as actions from '../../store/actions'
import { connect } from 'react-redux'
import Autocomplete from '@material-ui/lab/Autocomplete'

const HomeResources = ({ resources, getAllResources, history }) => {
    const [search, setSearch] = useState('')
    const [filtered, setFiltered] = useState(resources)

    useEffect(() => {
        if (resources.length === 0) getAllResources()
    }, [resources.length, getAllResources])

    useEffect(() => {
        let temp = resources
            .filter((a) => a.description.toLowerCase().includes(search))
            .sort((a, b) => a.resourceType.localeCompare(b.resourceType))

        if (temp.length > 8) temp.length = 8
        setFiltered(temp)
    }, [search, setSearch])

    return (
        <>
            <div className='topmenu-title'>
                <h5>Resources</h5>
            </div>
            <form className='search-form'>
                <div className='row'>
                    <Autocomplete
                        id='resourcesAutocomplete'
                        options={filtered}
                        noOptionsText={'Type to retrieve results..'}
                        groupBy={(g) => g.resourceType}
                        getOptionLabel={(o) => o.description}
                        onInputChange={(e) => setSearch(e.target.value)}
                        onChange={(event, value) =>
                            history.push('/resources/' + value.id)
                        }
                        renderInput={(params) => (
                            <div
                                className='input-field col s6'
                                ref={params.InputProps.ref}
                            >
                                <input
                                    id='search'
                                    type='text'
                                    className='validate'
                                    {...params.inputProps}
                                />
                                <label htmlFor='search'>Search</label>
                            </div>
                        )}
                    />
                    <div className='input-field col s6'>
                        <button
                            className='btn btn-small'
                            onClick={(e) =>
                                history.push(`/resources?search=${search}`)
                            }
                        >
                            Search
                        </button>
                    </div>
                </div>
            </form>
        </>
    )
}

const mapStateToProps = ({ resources, errors }) => {
    return { resources, errors }
}

export default connect(mapStateToProps, actions)(HomeResources)
