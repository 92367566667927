import 'materialize-css/dist/css/materialize.min.css'
import React from 'react'
import ReactDOM from 'react-dom'
import { processSilentRenew } from 'redux-oidc'
import App from './components/App'
import Root from './components/Root'

const root = document.getElementById('root')
const url = root.getAttribute('config-url')
const configPromise = fetch('/' + url)
if (window.location.pathname === '/oidc-silent-renew') {
    processSilentRenew()
} else {
    configPromise
        .then((res) => res.json())
        .then((config) => {
            ReactDOM.render(
                <Root config={config}>
                    <App />
                </Root>,
                root
            )
        })
}
