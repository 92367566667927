import React, { useState, useEffect, useCallback } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import * as actions from '../../store/actions'
import { connect } from 'react-redux'
import TopMenu from '../UI/TopMenu'
import Spinner from '../UI/Spinner'
import Table from '../UI/Table'
import qs from 'query-string'
import '../main/main.scss'

const Resources = ({ getAllResources, resources, history, location }) => {
    const page = 'Resources'
    const [search, setSearch] = useState('')
    const [filtered, setFiltered] = useState(resources)
    const [searchResources, setSearchResources] = useState()

    const retrieveData = useCallback(() => {
        getAllResources()
    }, [getAllResources])

    useEffect(retrieveData, [])

    useEffect(() => {
        if (resources.length !== 0) {
            const param = qs.parse(location.search).search
            if (param !== undefined) {
                setSearch(param)
                setSearchResources(
                    resources
                        .filter((f) =>
                            f.description
                                .toLowerCase()
                                .includes(param.toLowerCase())
                        )
                        .sort((a, b) =>
                            a.resourceType.localeCompare(b.resourceType)
                        )
                )
            }
        }
    }, [resources, getAllResources, location.search])

    const handleInputChange = (value) => {
        if (Number.isInteger(value)) return

        setSearch(value)

        let temp = filterAndSort(resources, value)

        if (temp.length > 8) temp.length = 8
        setFiltered(temp)
    }

    const handleSearch = (e) => {
        e.preventDefault()

        setSearchResources(filterAndSort(resources))
    }

    const filterAndSort = (data, value) => {
        return data
            .filter((f) =>
                f.description
                    .toLowerCase()
                    .includes(
                        value !== undefined
                            ? value.toLowerCase()
                            : search.toLowerCase()
                    )
            )
            .sort((a, b) => a.resourceType.localeCompare(b.resourceType))
    }

    return (
        <div className='container'>
            <TopMenu first='resources' second='Browse' page={page} />
            {resources !== null ? (
                <div className='row'>
                    <div className='col s12'>
                        <form className='search-form'>
                            <div className='row'>
                                <Autocomplete
                                    id='resourcesAutocomplete'
                                    options={filtered}
                                    noOptionsText={'Type to retrieve results..'}
                                    groupBy={(g) => g.resourceType}
                                    getOptionLabel={(o) => o.description}
                                    onInputChange={(e) =>
                                        handleInputChange(e.target.value)
                                    }
                                    onChange={(event, value) =>
                                        history.push('/resources/' + value.id)
                                    }
                                    renderInput={(params) => (
                                        <div
                                            className='input-field col s6'
                                            ref={params.InputProps.ref}
                                        >
                                            <input
                                                id='search'
                                                type='text'
                                                className='validate'
                                                {...params.inputProps}
                                            />
                                            <label htmlFor='search'>
                                                Search
                                            </label>
                                        </div>
                                    )}
                                />
                                <div className='input-field col s6'>
                                    <button
                                        className='btn btn-small'
                                        onClick={(e) => handleSearch(e)}
                                    >
                                        Search
                                    </button>
                                </div>
                            </div>
                        </form>
                        <Table
                            columns={[
                                { name: 'Description', align: 'left' },
                                { name: 'Id', align: 'left' },
                                { name: 'Type', align: 'right' },
                            ]}
                            rows={searchResources}
                            history={history}
                            type='resources'
                            fixedTable
                            headers={['account', 'accountgroup', 'company']}
                        />
                    </div>
                </div>
            ) : (
                <Spinner />
            )}
        </div>
    )
}

const mapStateToProps = ({ resources, errors }) => {
    return { resources, errors }
}

export default connect(mapStateToProps, actions)(Resources)
