import axios from '../../config/axios'
import {
    API_ERROR,
    GET_ALL_RESOURCES,
    GET_RESOURCE_BY_ID,
    GET_FILTERED_RESOURCES,
    GET_ALL_SUBJECTS,
    GET_FILTERED_SUBJECTS,
    GET_SUBJECT_BY_ID,
    GET_OBJECT_PERMISSIONS,
    GET_SUBJECT_PERMISSIONS,
    GET_ACTIONS,
    GET_MEMBERS,
    POST_SUBJECT_PERMISSION_STARTED,
    POST_SUBJECT_PERMISSION_SUCCESS,
    POST_SUBJECT_PERMISSION_FAILURE,
    DELETE_SUBJECT_PERMISSION_STARTED,
    DELETE_SUBJECT_PERMISSION_SUCCESS,
    DELETE_SUBJECT_PERMISSION_FAILURE,
} from './types'

export const getAllResources = () => async (dispatch) => {
    return axios
        .get(`/zar/api/v1/resources`)
        .then((res) => dispatch({ type: GET_ALL_RESOURCES, payload: res.data }))
        .catch((e) =>
            dispatch({ type: API_ERROR, error: e, payload: 'getAllResources' })
        )
}

export const getFilteredResources = (searchTerm) => async (dispatch) => {
    return axios
        .get(`/zar/api/v1/resources/filtered?searchTerm=${searchTerm}&limit=10`)
        .then((res) =>
            dispatch({ type: GET_FILTERED_RESOURCES, payload: res.data })
        )
        .catch((e) =>
            dispatch({ type: API_ERROR, error: e, payload: searchTerm })
        )
}

export const getResource = (id) => async (dispatch) => {
    return axios
        .get(`/zar/api/v1/resources/${id}`)
        .then((res) =>
            dispatch({ type: GET_RESOURCE_BY_ID, payload: res.data })
        )
        .catch((e) => dispatch({ type: API_ERROR, error: e, payload: id }))
}

export const getAllSubjects = () => async (dispatch) => {
    return axios
        .get(`/zar/api/v1/subjects`)
        .then((res) => dispatch({ type: GET_ALL_SUBJECTS, payload: res.data }))
        .catch((e) =>
            dispatch({ type: API_ERROR, error: e, payload: 'getAllSubjects' })
        )
}

export const getFilteredSubjects = (searchTerm) => async (dispatch) => {
    return axios
        .get(`/zar/api/v1/subjects/filtered?searchTerm=${searchTerm}&limit=10`)
        .then((res) =>
            dispatch({ type: GET_FILTERED_SUBJECTS, payload: res.data })
        )
        .catch((e) =>
            dispatch({ type: API_ERROR, error: e, payload: searchTerm })
        )
}

export const getSubject = (id) => async (dispatch) => {
    return axios
        .get(`/zar/api/v1/subjects/${id}`)
        .then((res) => dispatch({ type: GET_SUBJECT_BY_ID, payload: res.data }))
        .catch((e) => dispatch({ type: API_ERROR, error: e, payload: id }))
}

export const getObjectPermissions = (obj) => async (dispatch) => {
    return axios
        .get(
            `/zam/api/v0/management/objects/${obj}/permissions?includeParents=true`
        )
        .then((res) =>
            dispatch({
                type: GET_OBJECT_PERMISSIONS,
                payload: res.data,
                id: obj,
            })
        )
        .catch((e) =>
            dispatch({
                type: API_ERROR,
                error: e,
                payload: 'getObjectPermissions',
            })
        )
}

export const getSubjectPermissions = (obj) => async (dispatch) => {
    return axios
        .get(
            `/zam/api/v0/management/subjects/${obj}/permissions?includeInherited=true`
        )
        .then((res) =>
            dispatch({
                type: GET_SUBJECT_PERMISSIONS,
                payload: res.data,
                id: obj,
            })
        )
        .catch((e) =>
            dispatch({
                type: API_ERROR,
                error: e,
                payload: 'getSubjectPermissions',
            })
        )
}

export const getActions = () => async (dispatch) => {
    return axios
        .get(`/zam/api/v0/management/actions`)
        .then((res) => dispatch({ type: GET_ACTIONS, payload: res.data }))
        .catch((e) =>
            dispatch({ type: API_ERROR, error: e, payload: 'getActions' })
        )
}

export const getMembers = (roleType, roleName) => async (dispatch) => {
    return axios
        .get(`/zam/api/v0/management/roles/${roleType}/${roleName}/members`)
        .then((res) => dispatch({ type: GET_MEMBERS, payload: res.data }))
        .catch((e) =>
            dispatch({ type: API_ERROR, error: e, payload: 'getMembers' })
        )
}

export const postSubjectPermission = (object) => {
    return (dispatch) => {
        dispatch(postSubjectPermissionStarted())

        axios
            .post(
                `/zam/api/v0/management/objects/${object.obj}/permissions?aud=${object.aud}&sub=${object.sub}&act=${object.act}`
            )
            .then((res) =>
                dispatch(
                    postSubjectPermissionSuccess({
                        0: object.sub,
                        1: object.aud,
                        2: object.obj,
                        3: object.act,
                    })
                )
            )
            .catch((err) => {
                dispatch(postSubjectPermissionFailure(err.message))
            })
    }
}

export const postSubjectPermissionStarted = () => ({
    type: POST_SUBJECT_PERMISSION_STARTED,
})

export const postSubjectPermissionSuccess = (object) => ({
    type: POST_SUBJECT_PERMISSION_SUCCESS,
    payload: {
        object,
    },
})

export const postSubjectPermissionFailure = (error) => ({
    type: POST_SUBJECT_PERMISSION_FAILURE,
    payload: {
        error,
    },
})

export const deleteSubjectPermission = (object) => {
    return (dispatch) => {
        dispatch(deleteSubjectPermissionStarted())

        axios
            .delete(
                `/zam/api/v0/management/objects/${object.obj}/permissions?aud=${object.aud}&sub=${object.sub}&act=${object.act}`
            )
            .then((res) => dispatch(deleteSubjectPermissionSuccess(object)))
            .catch((err) => {
                dispatch(deleteSubjectPermissionFailure(err.message))
            })
    }
}

export const deleteSubjectPermissionStarted = () => ({
    type: DELETE_SUBJECT_PERMISSION_STARTED,
})

export const deleteSubjectPermissionSuccess = (object) => ({
    type: DELETE_SUBJECT_PERMISSION_SUCCESS,
    payload: {
        object,
    },
})

export const deleteSubjectPermissionFailure = (error) => ({
    type: DELETE_SUBJECT_PERMISSION_FAILURE,
    payload: {
        error,
    },
})
