import { GET_ALL_SUBJECTS } from '../actions/types'

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = [], action) => {
    switch (action.type) {
        case GET_ALL_SUBJECTS:
            return action.payload.items
        default:
            return state
    }
}
