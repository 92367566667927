import React from 'react'
import M from 'materialize-css/dist/js/materialize.min.js'
import * as actions from '../../store/actions'
import { connect } from 'react-redux'
import Icon from '../UI/Icon'

const DeletePermission = ({
    current,
    deleteSubjectPermission,
    permissions,
}) => {
    const handleModalClick = async (event, object) => {
        if (window.confirm('Are you sure you wish to delete this item?')) {
            const elem = document.getElementById('deletepermission-modal')
            const instance = M.Modal.init(elem)
            if (instance !== null) instance.open()

            let obj = {
                obj: object[2],
                aud: object[1],
                sub: object[0],
                act: object[3],
            }

            await deleteSubjectPermission(obj)
        }
    }

    let deleteRoleLoading = permissions.delete.loading
    let deleteRoleError = permissions.delete.error

    return (
        <>
            <button
                className='btn-flat'
                onClick={(event) => handleModalClick(event, current)}
            >
                <Icon type='delete' />
            </button>
            <div id='deletepermission-modal' className='modal'>
                <div className='modal-content'>
                    {deleteRoleLoading ? (
                        <>
                            <br />
                            <Icon type='loading' />
                        </>
                    ) : deleteRoleError ? (
                        <>
                            <br />
                            <Icon type='error' />
                            <span
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                {deleteRoleError}
                            </span>
                        </>
                    ) : (
                        <>
                            <br />
                            <Icon type='success' />
                            <span
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                Successfully deleted role
                            </span>
                        </>
                    )}
                </div>
                <div className='modal-footer'>
                    <button className='modal-close btn-flat'>Close</button>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = ({ permissions }) => {
    return { permissions }
}

export default connect(mapStateToProps, actions)(DeletePermission)
