import axios from 'axios'
import store from '../store'

axios.interceptors.request.use(
    config => {
        const token = store.getState().auth.user.access_token

        if (token != null) {
            config.headers.Authorization = `Bearer ${token}`
        }

        return config
    },
    err => {
        return Promise.reject(err)
    }
)

export default axios
