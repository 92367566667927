import React from 'react'
import { useSelector } from 'react-redux'
import Icon from '../UI/Icon'

const ErrorsHandler = () => {
    // a few bugs here
    // TODO: if subject doesnt exist in zar, but has permissions in zam - dont show error / 404
    const errors = useSelector((state) => state.errors)
    return (
        errors && (
            <div className='row errors-row'>
                <div className='col s12'>
                    <div className='chip red darken-1 white-text'>
                        <i className=' close material-icons right white-text'>
                            close
                        </i>
                        <Icon type='error-icon' /> An error occured while
                        executing {errors.id} <br />
                        <br />
                        {errors.e.message}
                    </div>
                </div>
            </div>
        )
    )
}

export default ErrorsHandler
