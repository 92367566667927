export const API_ERROR = 'API_ERROR'
export const GET_FILTERED_RESOURCES = 'GET_FILTERED_RESOURCES'
export const GET_ALL_RESOURCES = 'GET_ALL_RESOURCES'
export const GET_RESOURCE_BY_ID = 'GET_RESOURCE_BY_ID'
export const GET_ALL_SUBJECTS = 'GET_ALL_SUBJECTS'
export const GET_FILTERED_SUBJECTS = 'GET_FILTERED_SUBJECTS'
export const GET_SUBJECT_BY_ID = 'GET_SUBJECT_BY_ID'
export const GET_OBJECT_PERMISSIONS = 'GET_OBJECT_PERMISSIONS'
export const GET_SUBJECT_PERMISSIONS = 'GET_SUBJECT_PERMISSIONS'
export const GET_ACTIONS = 'GET_ACTIONS'
export const GET_MEMBERS = 'GET_MEMBERS'
export const POST_SUBJECT_PERMISSION_STARTED = 'POST_SUBJECT_PERMISSION_STARTED'
export const POST_SUBJECT_PERMISSION_SUCCESS = 'POST_SUBJECT_PERMISSION_SUCCESS'
export const POST_SUBJECT_PERMISSION_FAILURE = 'POST_SUBJECT_PERMISSION_FAILURE'
export const DELETE_SUBJECT_PERMISSION_STARTED =
    'DELETE_SUBJECT_PERMISSION_STARTED'
export const DELETE_SUBJECT_PERMISSION_SUCCESS =
    'DELETE_SUBJECT_PERMISSION_SUCCESS'
export const DELETE_SUBJECT_PERMISSION_FAILURE =
    'DELETE_SUBJECT_PERMISSION_FAILURE'
