import React from 'react'
import Gravatar from 'react-gravatar'

const Icon = ({ type, email }) => {
    switch (type) {
        case 'account':
            return <span className='material-icons'>list_alt</span>
        case 'accountgroup':
            return <span className='material-icons'>group_work</span>
        case 'reconcilegroup':
            return <span className='material-icons'>group_work</span>
        case 'company':
            return <span className='material-icons'>home_work</span>
        case 'user':
            return (
                <Gravatar
                    className='material-icons gravatar-icon circle'
                    email={email}
                />
            )
        case 'userrole':
            return <span className='material-icons'>group</span>
        case 'apikey':
            return <span className='material-icons'>vpn_key</span>
        case 'client':
            return <span className='material-icons'>developer_mode</span>
        case 'clear':
            return <span className='material-icons'>clear</span>
        case 'filter':
            return <span className='material-icons'>filter_list</span>
        case 'delete':
            return <span className='material-icons red-text'>delete</span>
        case 'error-icon':
            return <span className='material-icons'>error</span>
        case 'error':
            return (
                <div
                    className='swal2-icon swal2-error swal2-animate-error-icon'
                    style={{ display: 'flex' }}
                >
                    <span className='swal2-x-mark'>
                        <span className='swal2-x-mark-line-left'></span>
                        <span className='swal2-x-mark-line-right'></span>
                    </span>
                </div>
            )
        case 'success':
            return (
                <div
                    className='swal2-icon swal2-success swal2-animate-success-icon'
                    style={{ display: 'flex' }}
                >
                    {/* <div
                        className='swal2-success-circular-line-left'
                        style={{
                            backgroundColor: 'rgb(255, 255, 255)'
                        }}
                    ></div> */}
                    <span className='swal2-success-line-tip'></span>
                    <span className='swal2-success-line-long'></span>
                    <div className='swal2-success-ring'></div>
                    {/* <div
                        className='swal2-success-fix'
                        style={{
                            backgroundColor: 'rgb(255, 255, 255)'
                        }}
                    ></div> */}
                    <div
                        className='swal2-success-circular-line-right'
                        style={{
                            backgroundColor: 'transparent',
                        }}
                    ></div>
                </div>
            )
        case 'loading':
            return (
                <div
                    className='preloader-wrapper active'
                    style={{ display: 'flex', margin: '0 auto' }}
                >
                    <div className='spinner-layer spinner-blue-only'>
                        <div className='circle-clipper left'>
                            <div className='circle'></div>
                        </div>
                        <div className='gap-patch'>
                            <div className='circle'></div>
                        </div>
                        <div className='circle-clipper right'>
                            <div className='circle'></div>
                        </div>
                    </div>
                </div>
            )
        default:
            return null
    }
}

export default Icon
