import React, { useState, useEffect } from 'react'
import * as actions from '../../store/actions'
import { connect } from 'react-redux'
import Autocomplete from '@material-ui/lab/Autocomplete'

const HomeSubjects = ({ subjects, getAllSubjects, history }) => {
    const [search, setSearch] = useState('')
    const [filtered, setFiltered] = useState(subjects)

    useEffect(() => {
        if (subjects.length === 0) getAllSubjects()
    }, [subjects.length, getAllSubjects])

    useEffect(() => {
        let temp = subjects
            .filter((a) =>
                a.name === null ? a.name : a.name.toLowerCase().includes(search)
            )
            .sort((a, b) => a.subjectType.localeCompare(b.subjectType))

        if (temp.length > 8) temp.length = 8
        setFiltered(temp)
    }, [search, setSearch])

    return (
        <>
            <div className='topmenu-title'>
                <h5>Subjects</h5>
            </div>
            <form className='search-form'>
                <div className='row'>
                    <Autocomplete
                        id='subjectsAutocomplete'
                        options={filtered}
                        noOptionsText={'Type to retrieve results..'}
                        groupBy={(g) => g.subjectType}
                        getOptionLabel={(o) => o.name}
                        onInputChange={(e) => setSearch(e.target.value)}
                        onChange={(event, value) =>
                            history.push('/subjects/' + value.id)
                        }
                        renderInput={(params) => (
                            <div
                                className='input-field col s6'
                                ref={params.InputProps.ref}
                            >
                                <input
                                    id='search'
                                    type='text'
                                    className='validate'
                                    {...params.inputProps}
                                />
                                <label htmlFor='search'>Search</label>
                            </div>
                        )}
                    />
                    <div className='input-field col s6'>
                        <button
                            className='btn btn-small'
                            onClick={(e) =>
                                history.push(`/subjects?search=${search}`)
                            }
                        >
                            Search
                        </button>
                    </div>
                </div>
            </form>
        </>
    )
}

const mapStateToProps = ({ subjects, errors }) => {
    return { subjects, errors }
}

export default connect(mapStateToProps, actions)(HomeSubjects)
