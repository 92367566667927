import React, { useEffect } from 'react'
import { NavLink, Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Gravatar from 'react-gravatar'
import { userManager } from '../../tools/userManager'
import M from 'materialize-css/dist/js/materialize.min.js'
import zdata_logo from '../../images/zdata-logo-white.png'
import ErrorsHandler from './ErrorsHandler'
import './UI.scss'

const Navbar = () => {
    const auth = useSelector((state) => state.auth)

    useEffect(() => {
        M.AutoInit()
    })

    if (auth.user == null) {
        return <p>Error authenticating, relog on your provider.</p>
    }

    return (
        <>
            <nav className='z-depth-0'>
                <div className='nav-wrapper'>
                    <Link
                        to='#'
                        data-target='slide-out'
                        className='sidenav-trigger'
                    >
                        <i className='material-icons menu-icon'>menu</i>
                    </Link>
                    <Link id='logo-link' to='/'>
                        <img src={zdata_logo} alt='ZData Logo' />
                    </Link>
                    <span className='nav-wrapper-title'>
                        <Link to='/'>Access Management</Link>
                    </span>
                    <ul id='navbarUser'>
                        <li>
                            <a
                                className='dropdown-trigger'
                                data-target='user-dropdown'
                            >
                                <div className='navbar-account-wrapper'>
                                    <i className='material-icons'>
                                        account_circle
                                    </i>
                                    <i className='material-icons right'>
                                        arrow_drop_down
                                    </i>
                                </div>
                            </a>
                        </li>
                    </ul>
                    <ul id='user-dropdown' className='dropdown-content'>
                        <li>
                            <Link
                                to='#'
                                onClick={() => userManager.signoutRedirect()}
                            >
                                Logout
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav>
            <ul id='slide-out' className='sidenav sidenav-fixed'>
                <li>
                    <div className='user-view center-align'>
                        <Gravatar
                            className='circle img-avatar'
                            email={
                                auth.user === null
                                    ? 'support@zdata.no'
                                    : auth.user.profile.email
                            }
                            size={100}
                        />
                        <span className='name'>
                            {auth.user === null
                                ? 'unauthorized'
                                : auth.user.profile.full_name}
                        </span>
                        <span className='email'>
                            {auth.user === null
                                ? 'unauthorized'
                                : auth.user.profile.email}
                        </span>
                    </div>
                </li>
                <li></li>
                <li>
                    <Link to='/' className='subheader'>
                        Access Management
                    </Link>
                </li>
                <li>
                    <NavLink
                        exact
                        to='/'
                        activeClassName='active'
                        className='sidenav-item'
                    >
                        <i className='material-icons'>home</i>
                        Home
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to='/resources'
                        activeClassName='active'
                        className='sidenav-item'
                    >
                        <i className='material-icons'>compare_arrows</i>
                        Resources
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to='/subjects'
                        activeClassName='active'
                        className='sidenav-item'
                    >
                        <i className='material-icons'>subject</i>Subjects
                    </NavLink>
                </li>
                {/* <li>
                    <NavLink
                        to='/about'
                        activeClassName='active'
                        className='sidenav-item'
                    >
                        <i className='material-icons'>info</i>About
                    </NavLink>
                </li> */}
                <br />
                <ErrorsHandler />
            </ul>
        </>
    )
}

export default Navbar
