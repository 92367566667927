import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { userManager } from './userManager'
import Spinner from '../components/UI/Spinner'

class CheckAuth extends Component {
    state = { isValidUser: false }

    componentDidMount() {
        if (
            this.props.location &&
            this.props.location.pathname !== '/oidc-callback'
        ) {
            userManager.getUser().then(response => {
                if (!response || response.expired) {
                    userManager.signinRedirect({
                        data: {
                            path: window.location.pathname
                        }
                    })
                } else {
                    this.setState({
                        isValidUser: true
                    })
                }
            })
        } else {
            this.setState({
                isValidUser: true
            })
        }
    }

    render() {
        const { isValidUser } = this.state
        if (!isValidUser) {
            return <Spinner noPadding />
        } else {
            return this.props.children
        }
    }
}

export default withRouter(CheckAuth)
