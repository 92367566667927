import React, { useState, useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import toPascalCase from 'to-pascal-case'
import DeletePermission from '../utils/DeletePermission'
import Icon from '../UI/Icon'
import FilterMenu from '../UI/FilterMenu'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
}))

const DataTable = ({
    currentId,
    columns,
    rows,
    history,
    type,
    subjects,
    resources,
    fixedTable,
    headers,
}) => {
    const classes = useStyles()
    const [page, setPage] = React.useState(0)
    const [data, setData] = useState([])
    const [openFilterDialog, setOpenFilterDialog] = useState(false)
    const [rowsPerPage, setRowsPerPage] = React.useState(15)
    const [groupHeaders, setGroupHeaders] = useState([])
    let hideInheritedPermissions =
        localStorage.getItem('hideInheritedPermissions') === 'true'

    useEffect(() => {
        if (rows !== undefined) {
            setData(rows)
            filterInheritedPermissions(rows, hideInheritedPermissions)

            type === 'resources' &&
                headers.forEach((a) => {
                    setGroupHeaders((groupHeaders) => [
                        ...groupHeaders,
                        rows.find((r) => r.resourceType === a),
                    ])
                })

            type === 'subjects' &&
                headers.forEach((a) => {
                    setGroupHeaders((groupHeaders) => [
                        ...groupHeaders,
                        rows.find((r) => r.subjectType === a),
                    ])
                })

            type === 'subjectpermissions' &&
                headers.forEach((a) => {
                    setGroupHeaders((groupHeaders) => [
                        ...groupHeaders,
                        rows.find(
                            (r) => toPascalCase(getSubjectType(r[2])) === a
                        ),
                        rows.find(
                            (r) => toPascalCase(getSubjectType(r[0])) === a
                        ),
                    ])
                })

            type === 'objectpermissions' &&
                headers.forEach((a) => {
                    setGroupHeaders((groupHeaders) => [
                        ...groupHeaders,
                        rows.find(
                            (r) => toPascalCase(getSubjectType(r[0])) === a
                        ),
                    ])
                })

            type === 'members' &&
                headers.forEach((a) => {
                    setGroupHeaders((groupHeaders) => [
                        ...groupHeaders,
                        rows.find(
                            (r) => toPascalCase(getSubjectType(r[0])) === a
                        ),
                    ])
                })

            setGroupHeaders((headers) => Array.from(new Set(headers)))
        }
    }, [rows])

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    const getSubjectType = (id) => {
        return id === undefined ? '' : id.split('-', 2)[0]
    }

    const getSubjectName = (id) => {
        let subject = subjects[subjects.findIndex((r) => r.id === id)]
        return subject !== undefined ? subject.name : id
    }

    const getResourceName = (id) => {
        let resource = resources[resources.findIndex((r) => r.id === id)]
        return resource !== undefined
            ? resource.description !== ''
                ? resource.description
                : id
            : id
    }

    const getMemberName = (id) => {
        const subject = subjects[subjects.findIndex((r) => r.id === id)]
        if (subject === undefined) {
            const resource = resources[resources.findIndex((r) => r.id === id)]
            return resource !== undefined
                ? resource.description !== ''
                    ? resource.description
                    : id
                : id
        }
        return subject !== undefined ? subject.name : id
    }

    const isResource = (id) => {
        const subject = subjects.findIndex((r) => r.id === id)
        return subject !== -1 ? false : true
    }

    const getUserEmail = (id) => {
        let user = rows.filter((s) => s.id === id)
        let userName = user.map((m) => m.properties.userName)[0]
        let userEmail = user.map((m) => m.properties.email)[0]

        if (user && userEmail && userEmail.includes('@')) return userEmail
        else if (user && userName && userName.includes('@')) return userName

        return 'support@zdata.no'
    }

    const isPermissions =
        type === 'objectpermissions' || type === 'subjectpermissions'

    // if 'Hide Inherited Permissions' enabled, filters out inherited permissions
    const filterInheritedPermissions = (array, value) => {
        localStorage.setItem('hideInheritedPermissions', value)
        if (type === 'objectpermissions') {
            value
                ? setData([...array.filter((a) => a[2] === currentId)])
                : setData(rows)
        } else if (type === 'subjectpermissions') {
            value
                ? setData([...array.filter((a) => a[0] === currentId)])
                : setData(rows)
        }
    }

    const handleFilter = (values) => {
        if (values === null || values.length === 0) {
            // checks if hideinheritedpermissions is checked and filters by it
            hideInheritedPermissions
                ? filterInheritedPermissions(rows, hideInheritedPermissions)
                : setData([...rows])
            return
        }

        // order of groups
        let groups =
            type === 'objectpermissions'
                ? ['subjecttypes', 'api', 'scope', 'role']
                : ['scope', 'api', 'subjecttypes', 'role']
        let filters = values.reduce(
            (current, v) => {
                let col = groups.indexOf(v.group)
                current[col].push(v.value)
                return current
            },
            [[], [], [], []]
        )

        let filtered = rows.filter((row) => {
            let isMatch = true
            filters.forEach((filterValues, idx) => {
                isMatch =
                    isMatch &&
                    (filterValues.length === 0 ||
                        filterValues.indexOf(row[idx]) >= 0 ||
                        filterValues.indexOf(
                            toPascalCase(getSubjectType(row[idx]))
                        ) >= 0 ||
                        filterValues.indexOf(
                            row[idx] !== currentId
                                ? 'inherited'
                                : 'thisresource'
                        ) >= 0)
            })
            return isMatch
        })

        // checks if hideinheritedpermissions is checked and filters by it
        hideInheritedPermissions
            ? filterInheritedPermissions(filtered, true)
            : setData([...filtered])
    }

    return (
        <>
            {isPermissions && (
                <div className='row'>
                    <div className='col s6'>
                        <div className='switch'>
                            <label>
                                <input
                                    type='checkbox'
                                    onChange={(e) =>
                                        filterInheritedPermissions(
                                            data,
                                            e.target.checked
                                        )
                                    }
                                    defaultChecked={hideInheritedPermissions}
                                />
                                <span className='lever'></span>
                                Hide Inherited Permissions
                            </label>
                        </div>
                    </div>
                    <div
                        className='col s6 right-align'
                        style={{ position: 'relative', right: '1em' }}
                    >
                        <button
                            className='btn-flat'
                            onClick={(e) =>
                                setOpenFilterDialog(e.currentTarget)
                            }
                        >
                            <Icon type='filter' />
                        </button>
                        <FilterMenu
                            open={Boolean(openFilterDialog)}
                            anchorEl={openFilterDialog}
                            subjectTypes={Array.from(new Set(headers))}
                            onClose={() => setOpenFilterDialog(false)}
                            onFilterChange={handleFilter}
                        />
                    </div>
                </div>
            )}
            <Paper className={classes.root}>
                <TableContainer className={classes.container}>
                    <Table
                        stickyHeader
                        aria-label='simple table'
                        size='medium'
                        className={classes.table}
                        style={{
                            tableLayout: `${fixedTable ? 'fixed' : 'auto'}`,
                        }}
                    >
                        <TableHead>
                            <TableRow>
                                {columns.map((c) => {
                                    return (
                                        <TableCell key={c.name} align={c.align}>
                                            {c.name}
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {data
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                .map((row) => {
                                    // for roles in subject where id is (*)
                                    // for example userrole-zdataadmin
                                    let subjectPermissionId =
                                        type === 'subjectpermissions' &&
                                        row[2] === '*'
                                            ? row[0]
                                            : row[2]
                                    return (
                                        <>
                                            {type === 'resources' && (
                                                <>
                                                    {groupHeaders.find(
                                                        (r) => r === row
                                                    ) && (
                                                        <TableRow
                                                            key={row}
                                                            className='group-headers'
                                                        >
                                                            <TableCell>
                                                                {row.resourceType ===
                                                                'accountgroup'
                                                                    ? 'Account Group'
                                                                    : row.resourceType}
                                                            </TableCell>
                                                            <TableCell></TableCell>
                                                            <TableCell></TableCell>
                                                        </TableRow>
                                                    )}

                                                    <TableRow
                                                        hover
                                                        key={row.id}
                                                        onClick={() =>
                                                            history.push(
                                                                `/resources/${row.id}`
                                                            )
                                                        }
                                                    >
                                                        <TableCell>
                                                            <Icon
                                                                type={
                                                                    row.resourceType
                                                                }
                                                            />
                                                            {row.description}
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                whiteSpace:
                                                                    'nowrap',
                                                            }}
                                                        >
                                                            {row.id}
                                                        </TableCell>
                                                        <TableCell align='right'>
                                                            {row.resourceType}
                                                        </TableCell>
                                                    </TableRow>
                                                </>
                                            )}

                                            {type === 'subjects' && (
                                                <>
                                                    {groupHeaders.find(
                                                        (r) => r === row
                                                    ) && (
                                                        <TableRow
                                                            key={row}
                                                            className='group-headers'
                                                        >
                                                            <TableCell>
                                                                {
                                                                    row.subjectType
                                                                }
                                                            </TableCell>
                                                            <TableCell></TableCell>
                                                            <TableCell></TableCell>
                                                        </TableRow>
                                                    )}

                                                    <TableRow
                                                        hover
                                                        key={row.id}
                                                        onClick={() =>
                                                            history.push(
                                                                `/subjects/${row.id}`
                                                            )
                                                        }
                                                    >
                                                        <TableCell>
                                                            {row.subjectType ===
                                                            'user' ? (
                                                                <Icon
                                                                    type={
                                                                        row.subjectType
                                                                    }
                                                                    email={getUserEmail(
                                                                        row.id
                                                                    )}
                                                                />
                                                            ) : (
                                                                <Icon
                                                                    type={
                                                                        row.subjectType
                                                                    }
                                                                />
                                                            )}
                                                            {row.name}
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                whiteSpace:
                                                                    'nowrap',
                                                            }}
                                                        >
                                                            {row.id}
                                                        </TableCell>
                                                        <TableCell align='right'>
                                                            {row.subjectType}
                                                        </TableCell>
                                                    </TableRow>
                                                </>
                                            )}

                                            {type === 'objectpermissions' && (
                                                <>
                                                    {groupHeaders.find(
                                                        (r) => r === row
                                                    ) && (
                                                        <TableRow
                                                            key={row}
                                                            className='group-headers'
                                                        >
                                                            <TableCell>
                                                                {toPascalCase(
                                                                    getSubjectType(
                                                                        row[0]
                                                                    )
                                                                )}
                                                            </TableCell>
                                                            <TableCell></TableCell>
                                                            <TableCell></TableCell>
                                                            <TableCell></TableCell>
                                                            <TableCell></TableCell>
                                                            <TableCell></TableCell>
                                                        </TableRow>
                                                    )}

                                                    <TableRow
                                                        hover
                                                        key={row[0]}
                                                        // onClick={() =>
                                                        //     history.push(
                                                        //         '/subjects/' +
                                                        //             row[0]
                                                        //     )
                                                        // }
                                                    >
                                                        <TableCell
                                                            onClick={() =>
                                                                history.push(
                                                                    `/subjects/${row[0]}`
                                                                )
                                                            }
                                                        >
                                                            {getSubjectType(
                                                                row[0]
                                                            ) === 'user' ? (
                                                                <Icon
                                                                    type={getSubjectType(
                                                                        row[0]
                                                                    )}
                                                                    email={getUserEmail(
                                                                        row[0]
                                                                    )}
                                                                />
                                                            ) : (
                                                                <Icon
                                                                    type={getSubjectType(
                                                                        row[0]
                                                                    )}
                                                                />
                                                            )}
                                                            {getSubjectName(
                                                                row[0]
                                                            )}
                                                        </TableCell>
                                                        <TableCell align='right'>
                                                            {toPascalCase(
                                                                getSubjectType(
                                                                    row[0]
                                                                )
                                                            )}
                                                        </TableCell>
                                                        <TableCell align='right'>
                                                            {row[1]}
                                                        </TableCell>
                                                        <TableCell align='right'>
                                                            {row[3]}
                                                        </TableCell>
                                                        <TableCell align='right'>
                                                            {row[2] !==
                                                            currentId
                                                                ? '* (Inherited)'
                                                                : 'This resource'}
                                                        </TableCell>
                                                        <TableCell align='right'>
                                                            {row[2] ===
                                                                currentId && (
                                                                <DeletePermission
                                                                    current={
                                                                        row
                                                                    }
                                                                />
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                </>
                                            )}

                                            {type === 'subjectpermissions' && (
                                                <>
                                                    {groupHeaders.find(
                                                        (r) => r === row
                                                    ) && (
                                                        <TableRow
                                                            key={row}
                                                            className='group-headers'
                                                        >
                                                            <TableCell>
                                                                {toPascalCase(
                                                                    getSubjectType(
                                                                        subjectPermissionId
                                                                    )
                                                                )}
                                                            </TableCell>
                                                            <TableCell></TableCell>
                                                            <TableCell></TableCell>
                                                            <TableCell></TableCell>
                                                            <TableCell></TableCell>
                                                            <TableCell></TableCell>
                                                        </TableRow>
                                                    )}

                                                    <TableRow
                                                        hover
                                                        key={
                                                            subjectPermissionId
                                                        }
                                                        // onClick={() =>
                                                        //     history.push(
                                                        //         '/subjects/' +
                                                        //             row[0]
                                                        //     )
                                                        // }
                                                    >
                                                        <TableCell
                                                            onClick={() =>
                                                                history.push(
                                                                    row[2] ===
                                                                        '*'
                                                                        ? `/subjects/${subjectPermissionId}`
                                                                        : `/resources/${subjectPermissionId}`
                                                                )
                                                            }
                                                        >
                                                            {getSubjectType(
                                                                subjectPermissionId
                                                            ) === 'user' ? (
                                                                <Icon
                                                                    type={getSubjectType(
                                                                        subjectPermissionId
                                                                    )}
                                                                    email={getUserEmail(
                                                                        subjectPermissionId
                                                                    )}
                                                                />
                                                            ) : (
                                                                <Icon
                                                                    type={getSubjectType(
                                                                        subjectPermissionId
                                                                    )}
                                                                />
                                                            )}
                                                            {getResourceName(
                                                                subjectPermissionId
                                                            )}
                                                        </TableCell>
                                                        <TableCell align='right'>
                                                            {toPascalCase(
                                                                getSubjectType(
                                                                    subjectPermissionId
                                                                )
                                                            )}
                                                        </TableCell>
                                                        <TableCell align='right'>
                                                            {row[1]}
                                                        </TableCell>
                                                        <TableCell align='right'>
                                                            {row[3]}
                                                        </TableCell>
                                                        <TableCell align='right'>
                                                            {row[0] !==
                                                            currentId
                                                                ? '* (Inherited)'
                                                                : 'This resource'}
                                                        </TableCell>
                                                        <TableCell align='right'>
                                                            {row[0] ===
                                                                currentId && (
                                                                <DeletePermission
                                                                    current={
                                                                        row
                                                                    }
                                                                />
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                </>
                                            )}
                                            {type === 'members' && (
                                                <>
                                                    {groupHeaders.find(
                                                        (r) => r === row
                                                    ) && (
                                                        <TableRow
                                                            key={row}
                                                            className='group-headers'
                                                        >
                                                            <TableCell>
                                                                {getSubjectType(
                                                                    row[0]
                                                                )}
                                                            </TableCell>
                                                            <TableCell></TableCell>
                                                            <TableCell></TableCell>
                                                        </TableRow>
                                                    )}

                                                    <TableRow
                                                        hover
                                                        key={row[0]}
                                                        onClick={() =>
                                                            history.push(
                                                                isResource(
                                                                    row[0]
                                                                )
                                                                    ? `/resources/${row[0]}`
                                                                    : `/subjects/${row[0]}`
                                                            )
                                                        }
                                                    >
                                                        <TableCell>
                                                            {getSubjectType(
                                                                row[0]
                                                            ) === 'user' ? (
                                                                <Icon
                                                                    type={getSubjectType(
                                                                        row[0]
                                                                    )}
                                                                    email={getUserEmail(
                                                                        row[0]
                                                                    )}
                                                                />
                                                            ) : (
                                                                <Icon
                                                                    type={getSubjectType(
                                                                        row[0]
                                                                    )}
                                                                />
                                                            )}
                                                            {getMemberName(
                                                                row[0]
                                                            )}
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                whiteSpace:
                                                                    'nowrap',
                                                            }}
                                                        >
                                                            {row[0]}
                                                        </TableCell>
                                                        <TableCell align='right'>
                                                            {getSubjectType(
                                                                row[0]
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                </>
                                            )}
                                        </>
                                    )
                                })}
                            {data.length === 0 && (
                                <>
                                    {(type === 'resources' ||
                                        type === 'subjects') && (
                                        <TableRow>
                                            <TableCell>
                                                No{' '}
                                                {type === 'resources'
                                                    ? 'resources'
                                                    : 'subjects'}{' '}
                                                found.
                                            </TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    )}
                                    {(type === 'objectpermissions' ||
                                        type === 'subjectpermissions') && (
                                        <TableRow>
                                            <TableCell>
                                                No permissions found.
                                            </TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    )}
                                    {type === 'members' && (
                                        <TableRow>
                                            <TableCell>
                                                No{' '}
                                                {isResource(currentId)
                                                    ? 'childrens'
                                                    : 'members'}{' '}
                                                found.
                                            </TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    )}
                                </>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[15, 30, 50]}
                    component='div'
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
        </>
    )
}

export default DataTable
