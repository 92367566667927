import React from 'react'
import { useLocation } from 'react-router-dom'
import TabBar from '../UI/TabBar'
import Table from '../UI/Table'
import toPascalCase from 'to-pascal-case'

const Controller = ({
    history,
    subjects,
    resources,
    currentId,
    permissions,
    members,
}) => {
    const currentTab = useLocation().hash.replace('#', '')

    const getSubjectType = (id) => {
        return id.split('-', 2)[0]
    }

    const render = () => {
        const headers = [
            ...new Set(members.map((a) => toPascalCase(getSubjectType(a[0])))),
        ]
        switch (currentTab) {
            case 'permissions':
                return (
                    <Table
                        columns={[
                            { name: 'Name', align: 'left' },
                            { name: 'Type', align: 'right' },
                            { name: 'Api', align: 'right' },
                            { name: 'Role', align: 'right' },
                            { name: 'Scope', align: 'right' },
                            { name: 'Action', align: 'right' },
                        ]}
                        rows={permissions}
                        history={history}
                        type='objectpermissions'
                        subjects={subjects}
                        currentId={currentId}
                        headers={['Apikey', 'Userrole', 'User', 'Client']}
                    />
                )
            case 'childrens':
                return (
                    <Table
                        columns={[
                            { name: 'Name', align: 'left' },
                            { name: 'Id', align: 'left' },
                            { name: 'Type', align: 'right' },
                        ]}
                        rows={members}
                        history={history}
                        type='members'
                        subjects={subjects}
                        resources={resources}
                        currentId={currentId}
                        headers={headers}
                        fixedTable
                    />
                )
            default:
                return (
                    <Table
                        columns={[
                            { name: 'Name', align: 'left' },
                            { name: 'Type', align: 'right' },
                            { name: 'Api', align: 'right' },
                            { name: 'Role', align: 'right' },
                            { name: 'Scope', align: 'right' },
                            { name: 'Action', align: 'right' },
                        ]}
                        rows={permissions}
                        history={history}
                        type='objectpermissions'
                        subjects={subjects}
                        currentId={currentId}
                        headers={['Apikey', 'Userrole', 'User', 'Client']}
                    />
                )
        }
    }
    return (
        <>
            <TabBar history={history} currentTab={currentTab} isResource />
            {render()}
        </>
    )
}

export default Controller
