import React from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Menu from '@material-ui/core/Menu'
import Select from 'react-select'

const FilterMenu = ({
    open,
    anchorEl,
    subjectTypes,
    onClose,
    onFilterChange,
}) => {
    const actions = useSelector((state) => state.actions.actions)
    const isResources = useLocation().pathname.includes('resources')
        ? true
        : false

    const apiTypes = [
        {
            value: '*',
            label: '*',
            group: 'api',
        },
        {
            value: 'bankservice',
            label: 'bankservice',
            group: 'api',
        },
        {
            value: 'reconcile',
            label: 'reconcile',
            group: 'api',
        },
        {
            value: 'zam',
            label: 'zam',
            group: 'api',
        },
        {
            value: 'zar',
            label: 'zar',
            group: 'api',
        },
    ]

    const options = [
        {
            label: isResources ? 'Subject Types' : 'Resource Types',
            options: subjectTypes.map((item) => {
                return { label: item, value: item, group: 'subjecttypes' }
            }),
        },
        {
            label: 'Api',
            options: apiTypes,
        },
        {
            label: 'Role',
            options: actions.map((item) => {
                return { label: item, value: item, group: 'role' }
            }),
        },
        {
            label: 'Scope',
            options: [
                {
                    label: 'This resource',
                    value: 'thisresource',
                    group: 'scope',
                },
                {
                    label: '* (Inherited)',
                    value: 'inherited',
                    group: 'scope',
                },
            ],
        },
    ]

    return (
        <Menu
            open={open}
            anchorEl={anchorEl}
            keepMounted
            onClose={onClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <div className='filter-menu'>
                <div className='row'>
                    <div className='col'>
                        <p className='MuiTypography-root MUIDataTableFilter-title-327 MuiTypography-body2'>
                            Filters
                        </p>
                    </div>
                </div>
                <div className='row'>
                    <div className='col l12'>
                        <Select
                            closeMenuOnSelect={false}
                            isMulti
                            options={options}
                            onChange={(e) => onFilterChange(e)}
                            menuPlacement='auto'
                            menuPosition='fixed'
                            className='react-select'
                            autosize='false'
                        />
                    </div>
                </div>
            </div>
        </Menu>
    )
}

export default FilterMenu
