import React, { useState, useEffect, useCallback } from 'react'
import * as actions from '../../store/actions'
import { connect } from 'react-redux'
import Controller from './Controller'
import TopMenu from '../UI/TopMenu'
import Spinner from '../UI/Spinner'
import NotFound from '../UI/NotFound'

const DetailedResource = ({
    currentResource,
    getResource,
    subjects,
    getAllSubjects,
    resources,
    getAllResources,
    permissions,
    getObjectPermissions,
    members,
    getMembers,
    actions,
    getActions,
    match,
    history,
    errors,
}) => {
    const id = match.params.id
    const [previousId, setPreviousId] = useState(0)

    const retrieveData = useCallback(() => {
        getAllSubjects()
        getAllResources()
        getActions()
    }, [getAllSubjects, getAllResources, getActions])

    useEffect(retrieveData, [])

    useEffect(() => {
        if (id !== previousId) {
            getResource(id)
            getObjectPermissions(id)
            getMembers('g2', id)
            setPreviousId(id)
        }
    }, [id])

    useEffect(() => {
        if (permissions.data.length !== 0 && actions.length !== 0) {
            // first sorting by type then by actions
            permissions.data.sort((a, b) => {
                const subjectType = (a, b) => a[0].localeCompare(b[0])
                const actionsSort = (a, b) => actions.actions.indexOf(a[3])
                actions.actions.indexOf(b[3])

                return subjectType(a, b) || actionsSort(a, b)
            })
        }
    }, [permissions.data, getObjectPermissions, actions, getActions])

    const getResourceType = (id) => {
        let type = id.split('-', 2)
        return type[0]
    }

    const isValidResource = (_id) => {
        let [type, id] = _id.split('-', 2)
        return type === undefined || id === undefined ? false : true
    }

    let pageTitle = id
    if (
        previousId === id &&
        currentResource !== null &&
        currentResource.id === id
    ) {
        pageTitle = currentResource.description
    }

    if (!isValidResource(id)) {
        return <NotFound history={history} />
    }

    return ((currentResource !== null && currentResource.id === id) ||
        (errors && errors.e && errors.id === id)) &&
        previousId === id &&
        actions.length !== 0 ? (
        <div className='container'>
            <TopMenu
                first='resources'
                second={getResourceType(id)}
                page={pageTitle}
                addRoleAssignment
                actions={actions}
                subjects={subjects}
            />
            <div className='row'>
                <div className='col s12'>
                    <Controller
                        history={history}
                        resources={resources}
                        subjects={subjects}
                        currentId={id}
                        permissions={permissions.data}
                        members={members}
                    />
                </div>
            </div>
        </div>
    ) : (
        <Spinner />
    )
}

const mapStateToProps = ({
    resources,
    currentResource,
    subjects,
    actions,
    members,
    permissions,
    errors,
}) => {
    return {
        resources,
        currentResource,
        subjects,
        actions,
        members,
        permissions,
        errors,
    }
}

export default connect(mapStateToProps, actions)(DetailedResource)
