import { GET_ACTIONS } from '../actions/types'

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = [], action) => {
    switch (action.type) {
        case GET_ACTIONS:
            return action.payload
        default:
            return state
    }
}
