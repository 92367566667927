import React from 'react'

import animationData from '../../animations/loading.json'
import { useSelector } from 'react-redux'
import Lottie from 'react-lottie'
import HomeResources from '../resources/HomeResources'
import HomeSubjects from '../subjects/HomeSubjects'

const Landing = ({ history }) => {
    const auth = useSelector((state) => state.auth)

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
    }

    return (
        <div className='container'>
            <div className='row'>
                <div className='col s12 breadcrumbs'>
                    <span className='breadcrumb'>Home</span>
                </div>
            </div>
            <div className='row'>
                <div className='col s6'>
                    <div id='home-message'>
                        <h5>
                            {auth.user == null
                                ? 'unauthorized'
                                : `Welcome ${auth.user.profile.full_name}`}
                        </h5>
                        <p>Control Resources & Subjects within ZData</p>
                    </div>
                </div>
                <div className='col s6'>
                    <Lottie
                        options={defaultOptions}
                        height={200}
                        width={200}
                        isClickToPauseDisabled={true}
                    />
                </div>
            </div>
            <div id='main-section' className='row'>
                <div className='col l6'>
                    <HomeResources history={history} />
                </div>
                <div className='col l6'>
                    <HomeSubjects history={history} />
                </div>
            </div>
        </div>
    )
}

export default Landing
