import React from 'react'
import { Link } from 'react-router-dom'
import toPascalCase from 'to-pascal-case'
import AddRoleAssignment from '../utils/AddRoleAssignment'

const TopMenu = ({
    first,
    second,
    third,
    page,
    addRoleAssignment,
    actions,
    subjects,
    resources,
}) => {
    return (
        <div className='row'>
            <div className='col s12 breadcrumbs'>
                {second ? (
                    <Link to={`/${first}`} className='breadcrumb'>
                        {toPascalCase(first)}
                    </Link>
                ) : (
                    <span className='breadcrumb'>{first}</span>
                )}
                <span className='breadcrumb'>{second}</span>
                {third && <span className='breadcrumb'>{third}</span>}
            </div>
            <div className='row'>
                <div className='col s8 topmenu-title'>
                    <h5>{page}</h5>
                </div>
                {addRoleAssignment && (
                    <AddRoleAssignment
                        actions={actions}
                        subjects={subjects}
                        resources={resources}
                        type={first}
                    />
                )}
            </div>
        </div>
    )
}

export default TopMenu
