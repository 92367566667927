import { API_ERROR } from '../actions/types'

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = null, action) => {
    switch (action.type) {
        case API_ERROR:
            return {
                ...state,
                e: action.error ? action.error : null,
                id: action.payload ? action.payload : null,
            }
        default:
            return state
    }
}
