import React from 'react'
import { Link } from 'react-router-dom'

const TabBar = ({ history, currentTab, isResource }) => {
    return (
        <div id='tabbar' className='row'>
            <div className='col s12'>
                <ul className='tabs'>
                    <li className='tab col s3'>
                        <Link
                            to='#permissions'
                            onClick={() => history.push('#permissions')}
                            className={`${
                                currentTab === 'permissions' ||
                                currentTab === ''
                                    ? 'active'
                                    : ''
                            }`}
                        >
                            Permissions
                        </Link>
                    </li>
                    <li className='tab col s3'>
                        {isResource ? (
                            <Link
                                to='#childrens'
                                onClick={() => history.push('#childrens')}
                                className={`${
                                    currentTab === 'childrens' ? 'active' : ''
                                }`}
                            >
                                Childrens
                            </Link>
                        ) : (
                            <Link
                                to='#members'
                                onClick={() => history.push('#members')}
                                className={`${
                                    currentTab === 'members' ? 'active' : ''
                                }`}
                            >
                                Members
                            </Link>
                        )}
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default TabBar
