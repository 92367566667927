import React from 'react'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import history from '../config/history'
import { OidcProvider, loadUser } from 'redux-oidc'
import { configureUserManager } from '../tools/userManager'
import store from '../store'

import { setConfig } from '../config/config'

export default props => {
    setConfig(props.config)

    const userManager = configureUserManager()
    loadUser(store, userManager)

    return (
        <Provider store={store}>
            <Router history={history}>
                <OidcProvider userManager={userManager} store={store}>
                    {props.children}
                </OidcProvider>
            </Router>
        </Provider>
    )
}
