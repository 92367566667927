import { combineReducers } from 'redux'
import { reducer as auth } from 'redux-oidc'
import errors from './errorsReducer'
import resources from './resourcesReducer'
import filteredResources from './filteredResourcesReducer'
import currentResource from './currentResourceReducer'
import subjects from './subjectsReducer'
import currentSubject from './currentSubjectReducer'
import permissions from './permissionsReducer'
import actions from './actionsReducer'
import members from './membersReducer'

export default combineReducers({
    auth,
    errors,
    resources,
    filteredResources,
    currentResource,
    subjects,
    currentSubject,
    permissions,
    actions,
    members,
})
