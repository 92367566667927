import {
    GET_OBJECT_PERMISSIONS,
    GET_SUBJECT_PERMISSIONS,
    POST_SUBJECT_PERMISSION_STARTED,
    POST_SUBJECT_PERMISSION_SUCCESS,
    POST_SUBJECT_PERMISSION_FAILURE,
    DELETE_SUBJECT_PERMISSION_STARTED,
    DELETE_SUBJECT_PERMISSION_SUCCESS,
    DELETE_SUBJECT_PERMISSION_FAILURE,
} from '../actions/types'

const initialState = {
    id: 0,
    data: [],
    post: {
        loading: false,
        error: null,
    },
    delete: {
        loading: false,
        error: null,
    },
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
    switch (action.type) {
        case GET_OBJECT_PERMISSIONS:
            return {
                ...state,
                id: action.id,
                data: action.payload.items,
            }
        case GET_SUBJECT_PERMISSIONS:
            return {
                ...state,
                id: action.id,
                data: action.payload.items,
            }
        case POST_SUBJECT_PERMISSION_STARTED:
            return {
                ...state,
                post: {
                    loading: true,
                },
            }
        case POST_SUBJECT_PERMISSION_SUCCESS:
            return {
                ...state,
                data: [...state.data, action.payload.object],
                post: {
                    loading: false,
                    error: null,
                },
            }
        case POST_SUBJECT_PERMISSION_FAILURE:
            return {
                ...state,
                post: {
                    loading: false,
                    error: action.payload.error,
                },
            }
        case DELETE_SUBJECT_PERMISSION_STARTED:
            return {
                ...state,
                delete: {
                    loading: true,
                },
            }
        case DELETE_SUBJECT_PERMISSION_SUCCESS:
            const object = action.payload.object
            const obj = state.data.find(
                (s) =>
                    s[0] === object.sub &&
                    s[1] === object.aud &&
                    s[2] === object.obj &&
                    s[3] === object.act
            )
            return {
                ...state,
                data: [...state.data.filter((d) => d !== obj)],
                delete: {
                    loading: false,
                    error: null,
                },
            }
        case DELETE_SUBJECT_PERMISSION_FAILURE:
            return {
                ...state,
                delete: {
                    loading: false,
                    error: action.payload.error,
                },
            }
        default:
            return state
    }
}
