import React, { useState, useEffect } from 'react'
import M from 'materialize-css/dist/js/materialize.min.js'
import * as actions from '../../store/actions'
import { connect } from 'react-redux'
import Autocomplete from '@material-ui/lab/Autocomplete'
import toPascalCase from 'to-pascal-case'
import Gravatar from 'react-gravatar'
import Icon from '../UI/Icon'
import './utils.scss'

const AddRoleAssignment = ({
    type,
    actions,
    subjects,
    resources,
    currentSubject,
    currentResource,
    postSubjectPermission,
    permissions,
}) => {
    const [role, setRole] = useState('read')
    const [audience, setAudience] = useState('*')
    const [members, setMembers] = useState([])
    const [selectedMembers, setSelectedMembers] = useState([])
    const [showAddAnimation, setShowAddAnimation] = useState(false)

    // A member is either a resource or subject in this context

    const modalClick = () => {
        // in case you click add role assignment multiple times, kills animation of earlier
        setShowAddAnimation(false)

        // filters default to 'user' for subjects and 'company' for resources
        filterByType()

        const elem = document.getElementById('addroleassignment-modal')
        const instance = M.Modal.init(elem)
        if (instance !== null) instance.open()
    }

    useEffect(() => {
        M.AutoInit()
        if (
            actions.length !== 0 &&
            ((subjects !== undefined && subjects.length !== 0) ||
                (resources !== undefined && resources.length !== 0))
        ) {
            filterByType()
        }
    }, [actions, subjects, resources])

    const filterByType = (filterType) => {
        switch (type) {
            case 'resources':
                setMembers(
                    filterSubjects(
                        subjects,
                        filterType !== undefined ? filterType : 'user'
                    )
                )
                break
            case 'subjects':
                setMembers(
                    filterResources(
                        resources,
                        filterType !== undefined ? filterType : 'company'
                    )
                )
                break
            default:
                console.log(`Unknown type for AddRoleAssignment, ${type}`)
        }
    }

    const handleOnChangeAutocomplete = (value) => {
        if (value !== null) {
            const obj = {
                member: value,
                aud: audience,
                act: role,
            }

            setMembers((members) => [
                ...members.filter((s) => s.id !== value.id),
            ])
            setSelectedMembers((selectedMembers) => [...selectedMembers, obj])
        }
    }

    const handleAddClick = async () => {
        setSelectedMembers([])
        setShowAddAnimation(true)

        for (let i in selectedMembers) {
            let obj
            switch (type) {
                case 'resources':
                    obj = {
                        obj: currentResource.id,
                        aud: selectedMembers[i].aud,
                        sub: selectedMembers[i].member.id,
                        act: selectedMembers[i].act,
                    }
                    break
                case 'subjects':
                    obj = {
                        obj: selectedMembers[i].member.id,
                        aud: selectedMembers[i].aud,
                        sub: currentSubject.id,
                        act: selectedMembers[i].act,
                    }
                    break
                default:
                    console.log(`Unknown type for AddRoleAssignment, ${type}`)
            }

            await postSubjectPermission(obj)
        }
    }

    const removeSelectedMember = (value) => {
        const newMembersArray = [...members, value]
        type === 'resources' &&
            setMembers(
                newMembersArray.sort((a, b) => a.name.localeCompare(b.name))
            )
        type === 'subjects' &&
            setMembers(
                newMembersArray.sort((a, b) =>
                    a.description.localeCompare(b.description)
                )
            )
        setSelectedMembers((selectedMembers) => [
            ...selectedMembers.filter((s) => s.member.id !== value.id),
        ])
    }

    const filterSubjects = (subjects, subjectType) => {
        return subjects.filter(
            (s) =>
                s.subjectType === subjectType &&
                s.name !== null &&
                s.name !== ''
        )
    }

    const filterResources = (resources, resourceType) => {
        return resources.filter(
            (s) =>
                s.resourceType === resourceType &&
                s.description !== null &&
                s.description !== ''
        )
    }

    const getEmailByUser = (user) => {
        return user !== null
            ? user.properties.email === null || user.properties.email === ''
                ? user.properties.userName === null ||
                  user.properties.userName === ''
                    ? null
                    : user.properties.userName
                : user.properties.email
            : null
    }

    const subjectTypes = [
        {
            value: 'user',
            name: 'User',
        },
        {
            value: 'client',
            name: 'Client',
        },
        {
            value: 'apikey',
            name: 'API key',
        },
    ]

    const resourceTypes = [
        {
            value: 'company',
            name: 'Company',
        },
        {
            value: 'account',
            name: 'Account',
        },
        {
            value: 'accountgroup',
            name: 'Account Group',
        },
    ]

    const audiences = [
        {
            value: '*',
            name: '* (All)',
        },
        {
            value: 'zar',
            name: 'ZData Resource Server',
        },
        {
            value: 'zam',
            name: 'ZData Access Management',
        },
        {
            value: 'bankservice',
            name: 'Bankservice',
        },
        {
            value: 'reconcile',
            name: 'Reconcile',
        },
    ]

    let addRoleLoading = permissions.post.loading
    let addRoleError = permissions.post.error

    return actions.length !== 0 ? (
        <>
            <div className='col s4'>
                <a className='add-role-assignment' onClick={() => modalClick()}>
                    <span className='material-icons'>add_box</span>
                    <span>Add role assignment</span>
                </a>
            </div>
            <div id='addroleassignment-modal' className='modal'>
                <div className='modal-content'>
                    <h5>Add Role Assignment</h5>
                    <br />
                    <div id='dropdown-input' className='row'>
                        <div className='col s6'>
                            <div className='input-field'>
                                <select
                                    required
                                    onChange={(e) => setRole(e.target.value)}
                                    value={role}
                                >
                                    {actions.actions.map((a) => (
                                        <option key={a} value={a}>
                                            {toPascalCase(a)}
                                        </option>
                                    ))}
                                </select>
                                <label>Role</label>
                            </div>
                        </div>
                        <div className='col s6'>
                            <div className='input-field'>
                                <select
                                    required
                                    onChange={(e) =>
                                        setAudience(e.target.value)
                                    }
                                    value={audience}
                                >
                                    {audiences.map((s) => (
                                        <option key={s.value} value={s.value}>
                                            {s.name}
                                        </option>
                                    ))}
                                </select>
                                <label>Audience</label>
                            </div>
                        </div>
                    </div>
                    <div id='dropdown-input' className='row'>
                        <div className='col s12'>
                            <div className='input-field'>
                                <select
                                    required
                                    onChange={(e) =>
                                        filterByType(e.target.value)
                                    }
                                >
                                    {type === 'resources' &&
                                        subjectTypes.map((s) => (
                                            <option
                                                key={s.value}
                                                value={s.value}
                                            >
                                                {s.name}
                                            </option>
                                        ))}
                                    {type === 'subjects' &&
                                        resourceTypes.map((s) => (
                                            <option
                                                key={s.value}
                                                value={s.value}
                                            >
                                                {s.name}
                                            </option>
                                        ))}
                                </select>
                                <label>Assign access to</label>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        {type === 'resources' && (
                            <Autocomplete
                                id='selectMembersAutocomplete'
                                options={members}
                                noOptionsText={'No members found.'}
                                getOptionLabel={(o) => o.name}
                                // onInputChange={e => console.log(e.target.value)}
                                onChange={(event, value) =>
                                    handleOnChangeAutocomplete(value)
                                }
                                renderOption={(option) => (
                                    <div
                                        key={option.id}
                                        className='list-members'
                                    >
                                        {option.subjectType === 'user' ? (
                                            <Gravatar
                                                className='circle img-avatar'
                                                email={
                                                    getEmailByUser(option) ===
                                                    null
                                                        ? 'support@zdata.no'
                                                        : getEmailByUser(option)
                                                }
                                            />
                                        ) : (
                                            <Icon type={option.subjectType} />
                                        )}
                                        <div>
                                            <p>{option.name}</p>
                                            <p className='list-members-id'>
                                                {option.subjectType === 'user'
                                                    ? getEmailByUser(option)
                                                    : option.id}
                                            </p>
                                        </div>
                                    </div>
                                )}
                                renderInput={(params) => (
                                    <div
                                        id='select-member-wrapper'
                                        className='input-field col s12'
                                        ref={params.InputProps.ref}
                                    >
                                        <input
                                            id='select-member'
                                            type='text'
                                            className='validate'
                                            {...params.inputProps}
                                        />
                                        <label htmlFor='select-member'>
                                            Select
                                        </label>
                                    </div>
                                )}
                            />
                        )}
                        {type === 'subjects' && (
                            <Autocomplete
                                id='selectMembersAutocomplete'
                                options={members}
                                noOptionsText={'No resources found.'}
                                getOptionLabel={(o) => o.description}
                                // onInputChange={e => console.log(e.target.value)}
                                onChange={(event, value) =>
                                    handleOnChangeAutocomplete(value)
                                }
                                renderOption={(option) => (
                                    <div
                                        key={option.id}
                                        className='list-members'
                                    >
                                        <Icon type={option.resourceType} />
                                        <div>
                                            <p>{option.description}</p>
                                            <p className='list-members-id'>
                                                {option.id}
                                            </p>
                                        </div>
                                    </div>
                                )}
                                renderInput={(params) => (
                                    <div
                                        id='select-member-wrapper'
                                        className='input-field col s12'
                                        ref={params.InputProps.ref}
                                    >
                                        <input
                                            id='select-member'
                                            type='text'
                                            className='validate'
                                            {...params.inputProps}
                                        />
                                        <label htmlFor='select-member'>
                                            Select
                                        </label>
                                    </div>
                                )}
                            />
                        )}
                    </div>
                    <div id='selected-members' className='row'>
                        <div className='col s12'>
                            <span>Selected members</span>
                            <br />
                            <ul>
                                {showAddAnimation && (
                                    <>
                                        {addRoleLoading ? (
                                            <>
                                                <br />
                                                <Icon type='loading' />
                                            </>
                                        ) : addRoleError ? (
                                            <>
                                                <br />
                                                <Icon type='error' />
                                                <span
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                    }}
                                                >
                                                    {addRoleError}
                                                </span>
                                            </>
                                        ) : (
                                            <>
                                                <br />
                                                <Icon type='success' />
                                                <span
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                    }}
                                                >
                                                    Successfully added to role
                                                </span>
                                            </>
                                        )}
                                    </>
                                )}
                                {selectedMembers.map(
                                    (s) =>
                                        s !== null && (
                                            <li
                                                key={s.member.id}
                                                className='selected-member row'
                                            >
                                                <div className='col s1'>
                                                    {type === 'resources' &&
                                                    s.member.subjectType ===
                                                        'user' ? (
                                                        <Gravatar
                                                            className='circle img-avatar'
                                                            email={
                                                                getEmailByUser(
                                                                    s.member
                                                                ) === null
                                                                    ? 'support@zdata.no'
                                                                    : getEmailByUser(
                                                                          s.member
                                                                      )
                                                            }
                                                        />
                                                    ) : (
                                                        <Icon
                                                            type={
                                                                s.member
                                                                    .subjectType
                                                            }
                                                        />
                                                    )}
                                                    {type === 'subjects' && (
                                                        <Icon
                                                            type={
                                                                s.member
                                                                    .resourceType
                                                            }
                                                        />
                                                    )}
                                                </div>
                                                <div className='col s10'>
                                                    <p>
                                                        {type === 'resources' &&
                                                            s.member.name}
                                                    </p>
                                                    <p>
                                                        {type === 'subjects' &&
                                                            s.member
                                                                .description}
                                                    </p>
                                                    <p className='selected-member-email'>
                                                        {s.member
                                                            .subjectType ===
                                                        'user'
                                                            ? getEmailByUser(
                                                                  s.member
                                                              )
                                                            : s.member.id}
                                                    </p>
                                                </div>
                                                <div className='col s1'>
                                                    <span
                                                        className='material-icons remove-member red-text'
                                                        onClick={() =>
                                                            removeSelectedMember(
                                                                s.member
                                                            )
                                                        }
                                                    >
                                                        clear
                                                    </span>
                                                </div>
                                            </li>
                                        )
                                )}
                            </ul>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col s12'>
                            {selectedMembers.length !== 0 && (
                                <button
                                    className='btn btn-black btn-small'
                                    onClick={() => handleAddClick()}
                                >
                                    Add
                                </button>
                            )}
                        </div>
                    </div>
                </div>
                <div className='modal-footer'>
                    <button className='modal-close btn-flat'>Close</button>
                </div>
            </div>
        </>
    ) : (
        <p>Loading..</p>
    )
}

const mapStateToProps = ({
    currentSubject,
    currentResource,
    errors,
    permissions,
}) => {
    return { currentSubject, currentResource, errors, permissions }
}

export default connect(mapStateToProps, actions)(AddRoleAssignment)
