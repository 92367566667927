import React from 'react'
import Lottie from 'react-lottie'
import animationData from '../../animations/error.json'

const NotFound = ({ history }) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData
    }
    return (
        <div id='notfound' className='container'>
            <Lottie
                options={defaultOptions}
                height={300}
                width={300}
                isClickToPauseDisabled={true}
            />
            {/* <h1>404</h1> */}
            <h2>Oops! Nothing was found</h2>
            <p>
                The page you are looking for might have been removed, had its
                name changed or is temporarily unavailable.
            </p>
            <br />
            <button className='btn' onClick={history.goBack}>
                Go back
            </button>
        </div>
    )
}

export default NotFound
