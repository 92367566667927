import { createUserManager } from 'redux-oidc'
import { config } from '../config/config'

var userManager

function configureUserManager() {
    if (userManager === undefined) {
        userManager = createUserManager({
            client_id: 'ZamApp',
            redirect_uri: `${window.location.protocol}//${
                window.location.hostname
            }${
                window.location.port ? `:${window.location.port}` : ''
            }/oidc-callback`,
            response_type: 'id_token token',
            scope: 'openid profile zam resources.read',
            authority: config.REACT_APP_AUTH_URL,
            silent_redirect_uri: `${window.location.protocol}//${
                window.location.hostname
            }${
                window.location.port ? `:${window.location.port}` : ''
            }/oidc-silent-renew`,
            automaticSilentRenew: true,
            filterProtocolClaims: true,
            loadUserInfo: true
        })
    }
    return userManager
}

export { userManager, configureUserManager }
